<template>
  <main class="currentPost">
    <slide
      v-if="slides.length"
      :slides="slides"
    />
    <section class="postSection">
      <div class="asideLayout">
        <article>
          <div class="contactGroup">
            <aside>
              <p>
                Rua Diogo Moreira, 132 12o Andar
                05423-010 Pinheiros São Paulo/SP
              </p>
              <p class="contact">
                <span class="iconSpan">
                  <img
                    :src="phoneIcon"
                    alt="phone icon"
                  >
                </span>Tel + 55 11 3060 8296
              </p>
            </aside>
            <div class="content">
              <h1>Contato</h1>
              <ContactForm />
            </div>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>
<script>
import ContactForm from '@/components/ContactForm.vue';
import Slide from '@/components/slide.vue';

export default {
    name: 'ContactPage',
    components: {
        ContactForm,
        Slide,
    },
    data() {
        return {
            // eslint-disable-next-line global-require
            phoneIcon: require('@/assets/phone.svg'),
            slides: [{
                title: 'Fale conosco',
            }],
        };
    },
};
</script>
